import React from 'react'
import * as JudgesStyles from './Judges.module.scss'

/*
Hero Brown
Patrick Derham
Mark Semmance
Lucy Elphinstone
Guy Schady-Beckett
Tove Okinnawa
Roz Savage
Kieran Mclaughlin
Jess Wade
Rachel Jane
Will Phelps
Sophie Green
*/

const theJudges = [
  {
    name: 'HERO BROWN, EDITOR-IN-CHIEF, MUDDY STILETTOS & MUDDY STILETTOS BEST SCHOOLS GUIDE (CHAIR)',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/hero_brown.png',
    description:
      'A top national journalist for over 25 years across the UK’s broadsheet newspapers and women’s glossies, Hero Brown launched Muddy Stilettos when she moved out of London with her young family and couldn’t find any honest reviews on where to eat, stay, shop or send her kids to school. Ten years on, Muddy Stilettos is the UK’s leading luxury platform for women outside London, with 1.3 million avid users each month. The Best Schools Guide is one of the most popular parts of the site, with readers responding to Hero’s informal, honest and in-the-know take on the top Pre-Prep, Prep, Senior and Sixth Forms across 400+ independent school reviews.'
  },
  {
    name: 'PATRICK DERHAM, OBE, EX HEADMASTER, WESTMINSTER SCHOOL',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/patrick_derham.jpg',
    description:
      'Patrick had an unconventional upbringing: seven schools before the age of 12, and partly as a result of a dysfunctional family, he was taken in by the Shaftesbury Homes and lived on board the training ship Arethusa for two years. At 16 an unknown benefactor changed his life by paying for him to go to Pangbourne College and he became the first member of his family to go to university. After studying history at Cambridge, Patrick taught at Cheam and Radley before becoming headmaster of Solihull school in September 1996, Rugby in September 2001 and Westminster in 2014. Passionate about widening access, Patrick set up the Arnold Foundation for Rugby school in 2003, which provides a boarding education at Rugby to disadvantaged children, and was instrumental in setting up the national charity Springboard Bursary Foundation. He was a trustee of IntoUniversity from 2007 to 2020. In the Queen’s birthday Honours list 2018 Patrick was awarded an OBE for services to education.'
  },
  {
    name: 'MARK SEMMENCE, HEADMASTER, REPTON SCHOOL',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/mark_semmence.jpg',
    description:
      'Mark Semmence is the 36th Headmaster in Repton School’s 465 year history, and he has presided over the expansion of the Repton family of schools nationally and internationally (there are now four schools in the UK) and seven internationally with another three due to open in the next few years. Mark arrived at Repton following five years as Headmaster at Mount Kelly, where he oversaw a highly successful transformational programme (including the merger of Mount House and Kelly College) and seven years at Rugby School as Assistant Headmaster. Mark was educated at Durham (BA and MBA), Warwick (MA) and London (PGCE) Universities, and before his teaching career, he enjoyed a decade in the international sports marketing sector, working in London, New York, and Dubai with clients such as the ATP, ICC and the Cricket and Rugby World Cup organising committees.'
  },
  {
    name: 'EMMA GOLDSMITH, HEAD OF DRAGON SCHOOL, OXFORD',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/emma_goldsmith.jpg',
    description:
      'Emma Goldsmith has been Head of Dragon School in Oxford since September 2021. She is the twelfth person to lead the renowned day and boarding Prep School since it was founded in 1877 and the first woman to do so. Emma is enthusiastically involved with IAPS, is an active ISI inspector and Vice Chair of ISEB. Emma has considerable experience in co-educational prep and senior schools and enjoyed notable success during her years as Head of Winchester House School, awarded Prep Head of the Year. Before that, she taught at Oakham and Rugby and went on to become Deputy Head at Bloxham School where she also worked as Housemistress, setting up the first girls’ boarding house. Emma was educated at Durham Johnston Comprehensive before reading English at the University of Manchester.'
  },
  {
    name: 'LUCY ELPHINSTONE, FORMER HEADMISTRESS, FRANCIS HOLLAND SCHOOL, SLOANE SQUARE',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/lucy_elphinstone.jpg',
    description:
      'Lucy Elphinstone’s award-winning career in education spans leadership roles at Nursery, Prep and Senior schools. After a degree in English at Cambridge University, Lucy entered the world of publishing, eventually becoming a ghost writer for Hodder Headline and writing four books whilst bringing up her four children in Scotland. A move into Montessori and prep school teaching led to a role as Director of Drama at Fettes College, Edinburgh, followed by a relocation to England to become Head of English at King’s College Taunton and Head of Sixth Form at Downe House. Passionate about delivering outstanding pastoral care and wellbeing provision and developing entrepreneurial spirit in pupils, over the last nine years she has been the Headmistress at Francis Holland, Sloane Square. In 2021 Lucy was awarded Best Head of Public School (Tatler Awards) and the school won Outstanding New Initiative for Creative Enterprise Programme (Independent School Awards). She is now an educational consultant.'
  },
  {
    name: 'STEFAN ANDERSON, FORMER PRINCIPAL OF TRING PARK SCHOOL FOR THE PERFORMING ARTS',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/stefan_anderson.jpg',
    description:
      "Stefan was born in Toronto and educated in Ottawa. A graduate of the Royal Conservatory of Music, Toronto, he moved to England to study at the Royal College of Music and then Emmanuel College, Cambridge where he was Organ Scholar. He was appointed Assistant Director of Music and Organist of Wellington College in 1983, and then Director of Music at the King's School, Canterbury (1995-2002) before being appointed Principal at Tring Park School for the Performing Arts in Sept 2002. As a choral conductor, pianist and organist Stefan has performed and recorded extensively in the UK, Europe and North America. He’s been Chairman of the Music and Dance Scheme schools, a Director and member of the National Executive Committee of the Boarding Schools Association and Chairman of the Independent Schools Association (London North). He retired in 2023, and in his spare time he likes to travel, cook and the theatre."
  },
  {
    name: 'DR GUY SCHADY-BECKETT,  DIRECTOR, NEXT STEP EDUCATION',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/guy_schady-beckett.jpg',
    description:
      'As Head of Research at Next Step Education, organisers of the Independent Schools Show and the company behind Bonas MacFarlane, the tutoring and education advisors, Guy has a unique birds-eye view of the topics that seem to bother British and international parents. Helping educators to communicate with parents has occupied much of his career. Guy started out working in arts journalism, then after a brief spell at the whiteboard teaching English and drama (Windlesham, Durston House), in 1994 founded Drayson Beckett Ltd, an advertising and market research agency whose clients included a varied collection of independent schools (UCS, Highgate, Bute House, Wells, Stonar, Royal School Haslemere, Winchester House, Frensham Heights, Moreton Hall, Duke of Kent). Guy and his civil partner have two teenaged children, he is a member of the London Adopters Forum, sails precariously fast boats, is a life-long student, and convenes a public history seminar at the Institute of Historical Research.'
  },
  {
    name: 'TOVE OKUNNIWA, MBE',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/tove_okunniwa.jpg',
    description:
      'Tove has spent many years involved in all aspects of sport; as CEO of London Sport, a non-profit supported by Sport England and the Mayor of London, dedicated to making London the most active city in the world; leading two sport and entertainment marketing agencies - MEC Access and Havas Sports & Entertainment - advising clients such as Barclays, Coca-Cola, Visa, Danone, Lloyds Bank, Marriott, Specsavers and as Head of Marketing at BBC Sport. She is a Board Member of Sport England as well a trustee of Catch 22, a social business dedicated to helping young people, and was previously Vice-Chair of England Boxing,. In June 2021 Tove was awarded a MBE for Services To Sport.'
  },
  {
    name: 'DR ROZ SAVAGE, MBE',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/roz_savage.jpg',
    description:
      'Dr Roz Savage MBE is the first (and so far only) woman to row solo across the world’s “Big Three” oceans: Atlantic, Pacific, and Indian, using her adventures to raise awareness of environmental issues. She holds four Guinness World Records, and was awarded the MBE for services to fundraising and the environment. In 2010 she was named Adventurer of the Year by National Geographic. She has written four books - her latest, The Ocean in a Drop: Navigating from Crisis to Consciousness, is about how we all have a role to play in creating a better future for people and planet, and was described by Clare Balding as "candid, thought-provoking and visionary." She is also a keynote speaker, lecturer, blogger, and podcaster and a LibDem parliamentary candidate.'
  },
  {
    name: 'TOBY GARBETT',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/toby_garbett.png',
    description:
      'Toby is an Olympic and World Champion gold medal rower. He’s been an athlete mentor within schools for over 12 years and has worked with The Dame Kelly Homes Trust to inspire and motivate young people to reach their potential through sport. Toby is currently the Youth Sport Trust’s senior Athlete Mentor at the National School Games finals and works on programs including the FA’s Game On programme to promote girls’ football within schools, the Step in to Sport program working with disabled children and Project in Mind to support individuals with mental health. Toby has worked privately with many young athletes and sports teams, many of whom have gone on to international success but he also offers motivational mentoring workshops which cover physical and mental issues for all ages, interests and abilities. Still close to the river, living in Henley-on-Thames, he has just opened TG Gym and Wellness, a new facility for all.'
  },
  {
    name: 'KIERAN MCLAUGHLIN, HEADMASTER, DURHAM SCHOOL',
    image: '/best-schools-awards/judges/kieran-mclaughlin.avif',
    description:
      'Mr Kieran McLaughlin joined Durham School in September 2014 as Headmaster.  Born and raised in Liverpool he studied at St Edward’s College, a selective boys’ School in Liverpool before reading Natural Sciences at Jesus College, Cambridge, specialising in Physics and Theoretical Physics.  He has taught in City of London Girls’ School, Sevenoaks School and before Durham, was Deputy Head at Rugby School.  His interests outside school are the arts, travel and his family.'
  },
  {
    name: 'DR JESS WADE',
    image:
      'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/jess_wade.jpg',
    description:
      'Dr Jess Wade is an Imperial College Research Fellow working in the Department of Materials at Imperial College London. Her research considers new materials for optoelectronic devices, with a focus on chiral organic semiconductors and how to optimise these chiral systems such that they can absorb/emit circularly polarised light as well as transport spin-polarised electrons. Outside of the lab, Jess is involved with several science communication and outreach initiatives. She is committed to improving diversity in science, both online and offline.'
  },
  {
    name: 'RACHEL JANE, DEPUTY EDITOR, MUDDY STILETTOS',
    image: '/best-schools-awards/judges/rachel_jane.jpg',
    description:
      'A leading national journalist and editor with more than 25 years across the UK’s national tabloid newspapers and glossy magazines, Rachel Jane joined Muddy Stilettos in 2016, first as Berkshire editor with responsibility for all independent school reviews in the region, before becoming Deputy Editor of Muddy Stilettos UK and helping to oversee Muddy’s Best Schools Guide to 400+ top schools.'
  },
  {
    name: "SIOBAN O’CONNOR, DEBRETT'S EDUCATION",
    image: '/best-schools-awards/judges/sioban-oconnor.jpg',
    description:
      'Sioban ran her own theatre company for ten years, working with A level students across leading independent schools, before qualifying as a teacher in 2001. She gained a MA Education Management in 2006 and was one of the first independent school leaders to gain her NPQH, before becoming a Headteacher in 2010. A former A Level and GCSE examiner and School Governor, she retired from teaching in 2017 and has worked as an Education Consultant ever since, establishing Debrett’s Education in 2022.  She is a mother and grandmother and in her spare time….what spare time?'
  },
  {
    name: 'WILL PHELPS, HEADMASTER OF CHEAM SCHOOL, HEADLEY, BERKSHIRE',
    image: '/best-schools-awards/judges/will.png',
    description:
      'Will is a hugely experienced educationalist with excellent leadership skills allied to a passion for education with a strong focus on the happiness and success of the children. Previously, Will was Headmaster of The Beacon School, the largest boys prep school in the country. In 2010 he was appointed Head at The British International School of New York in Manhattan. Will also spent time at Abingdon School, Oxford where he taught Theology and became Senior House Master and Head of Boarding.'
  },
  {
    name: 'SOPHIE GREEN, CLERK TO THE GOVERNORS AT CHEAM SCHOOL',
    image: '/best-schools-awards/judges/sophie_green.jpg',
    description:
      "Prior to working at Cheam, Sophie was Headmistress at Godstowe School in High Wycombe where she was named 'Best Head of a Prep School' in the Tatler Schools Awards in 2021. Prior to her time at Godstowe, Sophie was Headmistress at Herries School in Maidenhead and before that she taught at the prestigious St George’s School, which lies within the grounds of Windsor Castle."
  }
  //   {
  //     name: 'SOPHIE GREEN',
  //     image:
  //       'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/judges/sophie_green.jpg',
  //     description: ''
  //   }
]

const Judges = () => {
  return (
    <>
      <div className={JudgesStyles.JudgesBlock}>
        {theJudges.map(judge => (
          <div key={judge.name} className={JudgesStyles.TheJudge}>
            <div className={JudgesStyles.TheJudge__photo}>
              <div className={JudgesStyles.TheJudge__photo__wrapper}>
                <img
                  data-cfsrc={judge.image}
                  alt={judge.name}
                  src={judge.image}
                />
              </div>
            </div>
            <div className={JudgesStyles.TheJudge__biog}>
              <h3>{judge.name}</h3>
              <p>{judge.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Judges
